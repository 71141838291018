import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DialogFormWrapper',{staticClass:"file-add",scopedSlots:_vm._u([{key:"form",fn:function(){return [_c('FileDragDrop',{attrs:{"single":""},on:{"error":_vm.showErrors},model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}}),_c(VExpandTransition,[(_vm.files.length)?_c(VForm,{staticClass:"pt-4"},[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"outlined":"","label":"Nazwa","rules":[_vm.rules.required],"placeholder":"Wpisz nazwę dokumentu"},model:{value:(_vm.file.name),callback:function ($$v) {_vm.$set(_vm.file, "name", $$v)},expression:"file.name"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VSelect,{attrs:{"outlined":"","label":"Typ dokumentu","menu-props":{offsetY: true},"items":_vm.clientFileTypes,"rules":[_vm.rules.required],"placeholder":"Wybierz typ dokumentu"},model:{value:(_vm.file.fileType),callback:function ($$v) {_vm.$set(_vm.file, "fileType", $$v)},expression:"file.fileType"}})],1)],1):_vm._e()],1)]},proxy:true},{key:"submit",fn:function(){return [(_vm.isUploadCompleted)?_c(VBtn,{staticClass:"base-hover",attrs:{"depressed":"","color":"primary"},on:{"click":_vm.clearForm}},[_vm._v(" Dodaj kolejny ")]):_c(VBtn,{staticClass:"base-hover",attrs:{"color":"primary","disabled":!_vm.files.length,"loading":_vm.isProcessing},on:{"click":_vm.sendFiles}},[_vm._v(" Załącz plik ")])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }