<template>
  <DialogFormWrapper class="file-add">
    <template #form>
      <FileDragDrop
        v-model="files"
        single
        @error="showErrors"
      />
      <v-expand-transition>
        <v-form
          v-if="files.length"
          class="pt-4"
        >
          <v-col cols="12">
            <v-text-field
              outlined
              label="Nazwa"
              v-model="file.name"
              :rules="[rules.required]"
              placeholder="Wpisz nazwę dokumentu"
            />
          </v-col>
          <v-col cols="12">
            <v-select
              outlined
              label="Typ dokumentu"
              v-model="file.fileType"
              :menu-props="{offsetY: true}"
              :items="clientFileTypes"
              :rules="[rules.required]"
              placeholder="Wybierz typ dokumentu"
            />
          </v-col>
        </v-form>
      </v-expand-transition>
    </template>

    <template #submit>
      <v-btn
        v-if="isUploadCompleted"
        depressed
        color="primary"
        class="base-hover"
        @click="clearForm"
      >
        Dodaj kolejny
      </v-btn>
      <v-btn
        v-else
        color="primary"
        class="base-hover"
        :disabled="!files.length"
        :loading="isProcessing"
        @click="sendFiles"
      >
        Załącz plik
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import FileDragDrop from './Inputs/FileDragDrop'
import DialogFormWrapper from './Partials/DialogFormWrapper'
import rules from '../../utils/validators'

export default {
  components: {
    FileDragDrop,
    DialogFormWrapper
  },

  data () {
    return {
      rules,
      isFormProcessing: false,
      isUploadCompleted: false,
      files: [],
      file: {
        name: '',
        fileType: 'Umowa'
      }
    }
  },

  computed: {
    ...mapState({
      isProcessing: state => state.client.isProcessing,
      clientFileTypes: state => state.core.filters.clientFileTypes,
      client: state => state.client.entity
    }),
    allUploadsCompleted () {
      return !!this.files.length && this.files.every((file) => file.status === 'uploaded' || file.status === 'error')
    }
  },

  watch: {
    allUploadsCompleted (status) {
      if (status) {
        this.isFormProcessing = false
        this.isUploadCompleted = true
      }
    },
    files (files) {
      if (files[0]) {
        const { name } = files[0].data
        this.file.name = name.split('.').shift()
      }
    }
  },

  methods: {
    ...mapActions({
      showSnackbar: 'snackbar/showSnackbar',
      uploadClientFile: 'client/uploadClientFile'
    }),

    showErrors (errors) {
      this.showSnackbar({
        message: errors,
        type: 'error'
      })
    },

    sendFiles () {
      this.isFormProcessing = true
      const file = this.files[0]

      const formData = new FormData()
      formData.append('file', file.data)
      formData.append('name', this.file.name)
      formData.append('file_type', this.file.fileType)
      formData.append('client_id', this.client.id)

      this.files[0].status = 'processing'

      this.uploadClientFile(formData)
        .then(() => {
          this.files[0].status = 'uploaded'
        })
        .catch(() => {
          this.files[0].status = 'error'
        })
    },
    clearForm () {
      this.files.splice(0, this.files.length)
      this.isUploadCompleted = false
      this.file = {
        name: '',
        fileType: 'Umowa'
      }
    }
  }
}
</script>
