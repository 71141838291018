<template>
  <div class="file-drag-drop">
    <form
      ref="fileForm"
      class="file-drag-drop__form"
    >
      <div
        v-if="files.length"
        class="file-drag-drop__listing"
      >
        <div
          v-for="(file, index) in files"
          :key="index"
          class="file-drag-drop__file"
        >
          <v-btn
            class="file-drag-drop__file-icon"
            tile
            fab
            dark
            x-small
            depressed
            color="white"
          >
            <v-icon
              color="primary"
              small
            >
              mdi-file
            </v-icon>
          </v-btn>
          <div class="file-drag-drop__file-name">
            {{ file.data.name }}
          </div>
          <v-btn
            v-if="file.status === 'pending'"
            icon
            x-small
            color="red"
            class="ml-auto"
            @click="removeFile"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-progress-circular
            v-if="file.status === 'processing'"
            indeterminate
            color="amber"
            size="24"
          />
          <v-icon
            v-if="file.status === 'uploaded'"
            medium
            color="green"
          >
            mdi-check
          </v-icon>
          <v-icon
            v-if="file.status === 'error'"
            medium
            color="red"
          >
            mdi-alert-circle-outline
          </v-icon>
        </div>
      </div>

      <div
        v-else
        class="file-drag-drop__drop-zone"
      >
        <label
          class="file-drag-drop__input-label"
          for="attachments"
        >
          <Icon name="folder-upload" />

          <div class="file-drag-drop__chose-text">
            Wybierz plik
          </div>
          <div class="file-drag-drop__description">
            Maksymalny rozmiar pliku 50 MB.<br>
            Dozwolone formaty to {{ acceptedFiles.join(', ') }}
          </div>
          <input
            id="attachments"
            class="file-drag-drop__input"
            :accept="acceptedFiles"
            type="file"
            size="60"
            multiple
            name="attachments"
            @change="addFiles"
          >
        </label>
      </div>
    </form>
  </div>
</template>

<script>
import Icon from '../../Elements/Icons/Icon'

const events = ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop']

export default {
  components: {
    Icon
  },
  props: {
    value: {
      type: Array,
      required: true
    },
    processing: {
      type: Boolean,
      default: false
    },
    single: {
      type: Boolean,
      default: false
    },
    outputFormats: {
      type: Array,
      default: () => ['doc', 'docx', 'jpeg', 'jpg', 'mov', 'mp4', 'pdf', 'png', 'webm', 'xls', 'xlsx', 'csv', 'txt']
    }
  },

  data () {
    return {
      dragAndDropCapable: false,
      errors: []
    }
  },

  computed: {

    files: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },

    acceptedFiles () {
      return this.outputFormats.filter((format) => format !== 'inny').map((format) => `.${format}`)
    }
  },

  mounted () {
    this.dragAndDropCapable = this.determineDragAndDropCapable()

    if (this.dragAndDropCapable) {
      events.forEach((evt) => {
        this.$refs.fileForm.addEventListener(evt, (e) => {
          e.preventDefault()
          e.stopPropagation()
        }, false)
      })

      this.$refs.fileForm.addEventListener('drop', (e) => {
        this.addFiles(e)
      })
    }
  },

  beforeDestroy () {
    events.forEach((evt) => {
      this.$refs.fileForm.removeEventListener(evt, (e) => {
        e.preventDefault()
        e.stopPropagation()
      })
    })
  },

  methods: {
    determineDragAndDropCapable () {
      const div = document.createElement('div')

      return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window
    },

    addFiles (e) {
      const fileList = e.target.files || e.dataTransfer.files
      const errors = []

      const megabyteInBytes = 1048576
      const limitInMegabytes = 50

      Array.from(fileList).forEach((file) => {
        const fileName = file.name
        const format = fileName.substr(fileName.lastIndexOf('.') + 1).toLowerCase()

        if (file.size / megabyteInBytes > limitInMegabytes) {
          errors.push(`Plik ${fileName} jest za duży`)
        } else if (this.outputFormats.indexOf(format) === -1) {
          errors.push(`Plik ${fileName} ma nieprawidłowy format`)
        } else {
          const fileData = {
            status: 'pending',
            data: file
          }
          this.files.push(fileData)
        }
      })

      if (errors.length) this.$emit('error', errors)
    },

    removeFile (index) {
      this.files.splice(index, 1)
    }
  }
}
</script>
